<template>

	<div id="portfolio">
		
			<div id="works" ref="works">
				
				<div id="projects" ref="projects">
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/01.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/02.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/03.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/04.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/05.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/06.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/07.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/08.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/09.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/10.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/11.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/12.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/13.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/14.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/15.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/16.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/17.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/18.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/19.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/20.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/21.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/22.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/23.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/24.jpg">
					</div>
					
					<div class="project">
						<img src="/gestorproyectos/public/images/portfolio/25.jpg">
					</div>
					
				</div>
				
				<div id="nav">
					<div class="side" id="prev" @mousedown="prev" @mouseup="stop" @touchstart="prev" @touchend="stop"></div>
					<div class="side" id="next" @mousedown="next" @mouseup="stop" @touchstart="next" @touchend="stop"></div>
				</div>
				
			</div>
			
			<div id="scroller" ref="scroller">
				<div class="line" ref="line"></div>
			</div>
			
	</div>
	
</template>

<script>
	export default {
		data() {
			return {
				position:0,
				images:25,
				width: 500,
				interval: null,
				area: 0,
				tomove: 0,
				active:false,
				currentX:0,
				currentY:0,
				initialX:0,
				initialY:0,
				xOffset:0,
				yOffset:0,
			};
		},
		computed:{
			
			positionx(){
				return this.position;
			},
			
		},
		methods: {
			next(){
				
				if(window.innerWidth > 800){
					
					this.stop();
					
					this.interval = setInterval(()=>{
						
						if(this.position < 100){
							this.position += .1;
						}else{
							this.stop();
						}
						
						this.movement();
						
					}, 50);
				
				}
				
			},
			
			prev(){
				
				if(window.innerWidth > 800){
				
					this.stop();
					
					this.interval = setInterval(()=>{
						
						if(this.position > 0){
							this.position -= .1;
						}else{
							this.stop();
						}
						
						this.movement();
						
					}, 50);
				
				}
				
			},
			
			stop(){
				
				clearInterval(this.interval);
			},
			
			changesize(){
				this.area = this.$refs.works.clientWidth;
				
				this.tomove = ((this.images * this.width) - this.area)/100;
			},
			
			
			movement(){
				
				this.$refs.projects.setAttribute("style", "transform: translateX(-" + this.tomove * this.position + "px)");
				
			},
			
			
			dragStart(e) {
				if (e.type === "touchstart") {
					this.initialX = e.touches[0].clientX - this.xOffset;
					this.initialY = e.touches[0].clientY - this.yOffset;
				} else {
					this.initialX = e.clientX - this.xOffset;
					this.initialY = e.clientY - this.yOffset;
				}
		
				if (e.target === this.$refs.line) {
					this.active = true;
				}
			},
		
			dragEnd(e) {
				this.initialX = this.currentX;
				this.initialY = this.currentY;
		
				this.active = false;
			},
		
			drag(e) {
				if (this.active) {
				
					e.preventDefault();
				
					if (e.type === "touchmove") {
						this.currentX = e.touches[0].clientX - this.initialX;
						this.currentY = e.touches[0].clientY - this.initialY;
					} else {
						this.currentX = e.clientX - this.initialX;
						this.currentY = e.clientY - this.initialY;
					}
		
					this.xOffset = this.currentX;
					this.yOffset = this.currentY;
					
					if(this.currentX > 0 && this.currentX < this.$refs.scroller.clientWidth){
					
						this.setTranslate(this.currentX, this.currentY);
						
						this.position = (this.currentX * 100)/this.$refs.scroller.clientWidth;
						this.movement();
					
					}
					
					//console.log(this.currentX);
				}
			},
		
			setTranslate(xPos, yPos) {
				this.$refs.line.style.transform = "translateX(" + xPos + "px)";
			},
			
			
		},
		mounted() {
			
			window.addEventListener('resize', this.changesize);
			
			this.changesize();
			
			
			this.$refs.scroller.addEventListener("touchstart", this.dragStart, false);
			this.$refs.scroller.addEventListener("touchend", this.dragEnd, false);
			this.$refs.scroller.addEventListener("touchmove", this.drag, false);
		
			this.$refs.scroller.addEventListener("mousedown", this.dragStart, false);
			this.$refs.scroller.addEventListener("mouseup", this.dragEnd, false);
			this.$refs.scroller.addEventListener("mousemove", this.drag, false);
			
			
		},
		destroy(){
			window.removeEventListener('resize',this.changesize);		
		},
	};
</script>