import { loadPrototypes, axiosInterceptors } from '@/helpers';
import _ from 'lodash';

try {
	loadPrototypes();

	window._ = _;
	window.axios = require('axios');
	window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

	// CSRF Token =========================
	let token = document.head.querySelector('meta[name="csrf-token"]');

	if (token) {
		window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
	} else {
		console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
	}

	// API Token =========================
	let apiToken = document.head.querySelector('meta[name="api-token"]');

	if (apiToken) {
		window.axios.defaults.headers.Authorization = 'Bearer ' + apiToken.content;
	}

	axiosInterceptors();
} catch (e) {
	console.error('Error cargando scripts', e);
}
