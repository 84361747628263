<template>

    <footer>
		
		
        
    </footer>

</template>

<script>
export default {
	
}
</script>



