var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "contacto" } }, [
    _c("div", { class: _vm.resultClass, attrs: { id: "formulario" } }, [
      _c("div", { staticClass: "side", attrs: { id: "left" } }, [
        _c("div", { staticClass: "input" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name",
              },
            ],
            attrs: { type: "text", placeholder: "Name" },
            domProps: { value: _vm.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.name = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            attrs: { type: "text", placeholder: "E-mail" },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.email = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.phone,
                expression: "phone",
              },
            ],
            attrs: { type: "text", placeholder: "Phone" },
            domProps: { value: _vm.phone },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.phone = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.business,
                expression: "business",
              },
            ],
            attrs: { type: "text", placeholder: "*Business" },
            domProps: { value: _vm.business },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.business = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "side" }, [
        _c("div", { staticClass: "input" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.message,
                expression: "message",
              },
            ],
            attrs: { name: "", placeholder: "Message", rows: "3" },
            domProps: { value: _vm.message },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.message = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "boton", on: { click: _vm.send } }, [
          _c("span", [_vm._v("Send")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "msg" } }, [_vm._v(_vm._s(_vm.notification))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }