export default {
	install(Vue, options) {
		Vue.mixin({
			filters: {
				money(price) {
					return '$' + parseFloat(price).toFixed(2);
				},
				alwaysTwoDigits(number) {
					return number < 10 ? '0' + number : number;
				},
			},

			methods: {
				getTotalFor(product) {
					return (product.variant ? product.variant.price : product.price) * product.quantity;
				},

				getTagsAsString(product) {
					var string = '';

					_.each(product.tags, tag => {
						string += tag.title + ' / ';
					});

					string = string.trim().slice(0, -1);

					return string;
				},

				onlyNumbers(event) {
					if (_.isNaN(parseInt(event.key))) {
						event.preventDefault();
					} else {
						return true;
					}
				},
			},
		});
	},
};
