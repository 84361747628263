var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.clase, attrs: { id: _vm.id } }, [
    _vm.choose == "logo"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 226.2 42.27",
            },
          },
          [
            _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
              _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
                _c("path", {
                  staticClass: "cls-1",
                  attrs: {
                    d: "M0,21.14C0,9.44,8.13,0,20.09,0s20.1,9.44,20.1,21.14S32,42.27,20.09,42.27,0,32.83,0,21.14Zm32.17,0C32.17,13.67,27.78,8,20.09,8S7.91,13.67,7.91,21.14s4.5,13.12,12.18,13.12S32.17,28.55,32.17,21.14Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-1",
                  attrs: {
                    d: "M71.81,41.73,62.26,26.46H56.55V41.73H48.26V.55H66.93c6.8,0,12.18,6.53,12.18,13.4,0,5.43-3.56,10.7-8.56,12L81.09,41.73Zm-.22-27.84c0-3.57-2.19-6.15-5-6.15h-10V20h10C69.45,20,71.59,17.57,71.59,13.89Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-1",
                  attrs: { d: "M97.23.55V41.73H88.94V.55Z" },
                }),
                _c("path", {
                  staticClass: "cls-1",
                  attrs: {
                    d: "M143.46,28.06V41.73h-4.92l-1.61-5c-3,3.84-7,5.27-12.52,5.27-11.75,0-19.55-8.07-19.55-20.64S112.61.44,124,.44C133.47.44,141.48,5,142.58,15h-8c-.71-4.23-4.67-7.08-9.77-7.08-8,0-12.3,5-12.3,13.45,0,8.23,5.55,13.17,11.8,13.17,5.6,0,10.66-2.8,10.66-7.9h-9.83V19.71h18.28Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-1",
                  attrs: {
                    d: "M160.48,17.51H177v7.25H160.48v9.72h18.83v7.25H152.19V.55h27.12V7.74H160.48Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-1",
                  attrs: {
                    d: "M226.2,41.73h-8.46l-21-28.66V41.73h-8.29V.55h8.45L218,29.15V.55h8.24Z",
                  },
                }),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.choose == "star"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 39.33 39.33",
            },
          },
          [
            _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
              _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
                _c("polygon", {
                  staticClass: "cls-1",
                  attrs: {
                    points:
                      "39.33 18.86 23.8 18.86 38.18 12.98 37.57 11.48 23.09 17.39 34.15 6.33 33 5.19 22.02 16.17 28.03 1.85 26.54 1.22 20.48 15.64 20.48 0 18.86 0 18.86 15.53 12.98 1.15 11.48 1.76 17.39 16.25 6.33 5.19 5.19 6.33 16.17 17.32 1.85 11.3 1.22 12.79 15.64 18.86 0 18.86 0 20.48 15.53 20.48 1.15 26.35 1.76 27.85 16.25 21.94 5.19 33 6.33 34.15 17.32 23.16 11.3 37.48 12.79 38.11 18.86 23.69 18.86 39.33 20.48 39.33 20.48 23.8 26.35 38.18 27.85 37.57 21.94 23.09 33 34.15 34.15 33 23.16 22.02 37.48 28.03 38.11 26.54 23.69 20.48 39.33 20.48 39.33 18.86",
                  },
                }),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.choose == "target"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 33.33 33.33",
            },
          },
          [
            _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
              _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
                _c("circle", {
                  staticClass: "cls-1",
                  attrs: { cx: "16.67", cy: "16.67", r: "10.67" },
                }),
                _c("line", {
                  staticClass: "cls-1",
                  attrs: { x1: "16.67", x2: "16.67", y2: "33.33" },
                }),
                _c("line", {
                  staticClass: "cls-1",
                  attrs: { x1: "33.33", y1: "16.67", y2: "16.67" },
                }),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.choose == "barcode"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 360.7 28.38",
            },
          },
          [
            _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
              _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { width: "5.07", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "21.92", width: "10.51", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "8.06", width: "2.54", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "12.95", width: "2.54", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "54.71", width: "5.07", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "37.59", width: "2.54", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "45.02", width: "2.54", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "59.56", width: "11.28", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "49.87", width: "2.54", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "268.03", width: "10.51", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "283.7", width: "2.54", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "291.13", width: "2.54", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "339.32", width: "2.54", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "344.39", width: "2.54", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: {
                    x: "350.28",
                    width: "0.91",
                    height: "28.38",
                    transform: "translate(701.46 28.38) rotate(180)",
                  },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: {
                    x: "352.18",
                    width: "0.91",
                    height: "28.38",
                    transform: "translate(705.27 28.38) rotate(180)",
                  },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "295.98", width: "2.54", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "82.07", width: "5.07", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "109.42", width: "5.07", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "89.49", width: "1.27", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "92.57", width: "1.27", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "95.65", width: "1.27", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "98.73", width: "1.27", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "203.99", width: "1.27", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "207.07", width: "1.27", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "212.46", width: "1.27", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "115.76", width: "1.27", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: {
                    x: "134.42",
                    width: "2.36",
                    height: "28.38",
                    transform: "translate(271.2 28.38) rotate(180)",
                  },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: {
                    x: "139.41",
                    width: "2.36",
                    height: "28.38",
                    transform: "translate(281.17 28.38) rotate(180)",
                  },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "118.66", width: "11.41", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "164.13", width: "5.07", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "155.35", width: "7.52", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "146.56", width: "5.07", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "175.91", width: "20.65", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "215.47", width: "8.45", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "246.2", width: "5.07", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "252.81", width: "5.07", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "225.73", width: "2.54", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "231.48", width: "5.66", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "240.36", width: "2.63", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "273.56", width: "5.07", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "259.88", width: "1.45", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "264.18", width: "1.45", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "300.91", width: "5.07", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "308.88", width: "24.46", height: "28.38" },
                }),
                _c("rect", {
                  staticClass: "cls-1",
                  attrs: { x: "355.62", width: "5.07", height: "28.38" },
                }),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }