var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "errorView" } }, [
      _c("div", { staticClass: "content" }, [
        _c("strong", [_vm._v("404")]),
        _vm._v(" "),
        _c("span", [_vm._v("Al parecer lo que buscas no está por aquí.")]),
        _vm._v(" "),
        _c("a", { attrs: { href: "https://planetoi.de", target: "_tab" } }, [
          _c("img", { attrs: { src: "/images/planetoide_w.svg" } }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }