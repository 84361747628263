<template>

	<div id="errorView">
		
		<div class="content">
			
			<strong>404</strong>
			<span>Al parecer lo que buscas no está por aquí.</span>

			<a href="https://planetoi.de" target="_tab">
				<img src="/images/planetoide_w.svg">
			</a>
			
		</div>
		
	</div>
	
</template>
<script>
export default {

}
</script>