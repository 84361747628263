const Site = {
	install(Vue, options) {
		Vue.mixin({
			components: {
				//'example-component': () => import('@/lazycomponents/ExampleComponent'),
			},
			data() {
				return {
					//
				};
			},
			methods: {
				getTranslation(text, lang) {
					text = decodeURIComponent(text);
					if (_.isNull(text) || _.isUndefined(text) || text.indexOf('##') < 0) {
						return text;
					}

					let pos = lang == 'es' ? '[0]' : '[1]';
					return _.get(_.split(text, '##'), pos, '');
				},
			},
		});
	},
};

export default Site;
