<template>
	<div id="cover">
		<div class="centro animation">
			
			<div id="box" :class="[{mid:mid},{full:full},{glow:glow}]">
			    <div class="half" id="right">
			        <div class="semicircle"></div>
			    </div>
			    <div class="half" id="left">
				    <div class="semicircle"></div>
			    </div>
			</div>
			
			<div id="box" :class="['min',{mid:mid2},{full:full2},{glow:glow2}]">
			    <div class="half" id="right">
			        <div class="semicircle"></div>
			    </div>
			    <div class="half" id="left">
				    <div class="semicircle"></div>
			    </div>
			</div>
			
			<div id="svganimation">
				<div id="circle" :class="{glow:glow}"></div>
				<svg xmlns="http://www.w3.org/2000/svg" width="400.2099914550781" height="400.1029968261719" viewBox="0 0 400.21 400.103"><path d="M-7424.715-3259.685c112.05,0,194.98,96.837,192.915,198.067-1.41,85.579-64.753,188.17-192.915,193.961-104.857.8-197-78.661-199.218-193.961s96.68-200.849,196.633-198.067" transform="translate(7627.971 3263.753)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6" class="svg-elem-1"></path></svg>
				<div id="dot" :class="[{dot:dot},{dotglow:dotglow}]"></div>
			</div>
			
			
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				mid:false,
				full: false,
				glow:false,
				mid2:false,
				full2: false,
				glow2:false,
				dot:false,
				dotglow:false,
			};
		},
		computed:{
		},
		methods: {
			start(){
				
				setTimeout(()=>{
					this.mid=true;
				}, 1000);
				
				setTimeout(()=>{
					this.full=true;
				}, 2000);
				
				setTimeout(()=>{
					this.glow=true;
				}, 3000);
				
				setTimeout(()=>{
					this.mid2=true;
				}, 4000);
				
				setTimeout(()=>{
					this.full2=true;
				}, 4400);
				
				setTimeout(()=>{
					this.glow2=true;
				}, 4800);
				
			},
			svgstart(){
				
				setTimeout(()=>{
					this.glow = true;
					this.dot = true;
					this.dotglow = true;
				}, 2000);
				
			},
		},
		mounted() {

			//this.start();
			this.svgstart();
			
		},
	};
</script>