export default {
	install(Vue, options) {
		Vue.config.productionTip = false;

		Vue.mixin({
			components: {
				'show-notifications': () => import('@/lazycomponents/ShowNotifications'),
				error: () => import('@/lazycomponents/Error'),
			},
			data() {
				return {
					//
				};
			},
			methods: {
				redirect(uri) {
					window.location.href = uri;
				},
				imgBackground(image_src) {
					if (_.isString(image_src) && image_src.length) {
						return 'background:url("' + image_src + '")no-repeat center center;';
					}

					return '';
				},
				emitGlobalEvent(name, payload) {
					window.Events.$emit(name, payload || null);
				},
				listenGlobalEvent(name, callback) {
					window.Events.$on(name, callback);
				},
				showAlerts(alerts) {
					this.emitGlobalEvent('showAlerts', alerts);
				},
				noty(message, type = 'info') {
					this.showAlerts([{ type: type, message: message }]);
				},
				requestViaForm(action, method, data) {
					let token = document.head.querySelector('meta[name="csrf-token"]').content;

					if (!token) {
						throw 'CSRF token not found: missing meta[name="csrf-token"]';
					}

					var [form, inputToken, inputMethod] = [document.createElement('form'), document.createElement('input'), document.createElement('input')];

					form.method = 'POST';
					form.action = action;
					form.style = 'display:none;';

					inputToken.name = '_token';
					inputToken.value = token;

					inputMethod.name = '_method';
					inputMethod.value = method;

					form.appendChild(inputToken);
					form.appendChild(inputMethod);

					if (data) {
						for (var key in data) {
							let inputData = document.createElement('input');
							inputData.name = key;
							inputData.value = data[key] instanceof Object ? JSON.stringify(data[key]) : data[key];
							form.appendChild(inputData);
						}
					}

					document.body.appendChild(form);

					form.submit();
				},
			},
		});
	},
};
