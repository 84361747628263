<template>
	<header>
		
		<div id="logo" :class="{dark:darktop}">
			<v-svg clase="logo" choose="logo"></v-svg>
		</div>
		
		<div id="from" :class="{dark:darktop}">
			<label>México, MMXVII. ORIGEN ESTUDIO.</label>
		</div>
		
		<div id="star" :class="{dark:darktop}">
			<v-svg clase="svg" choose="star"></v-svg>
		</div>
		
		<div id="target" :class="{dark:darkbottom}">
			<v-svg clase="svg" choose="target"></v-svg>
			<div id="steps">
				<div class="step" id="step1" :class="{active : step == 1}"><div class="focus"></div></div>
				<div class="step" id="step2" :class="{active : step == 2}"><div class="focus"></div></div>
				<div class="step" id="step3" :class="{active : step == 3}"><div class="focus"></div></div>
				<div class="step" id="step4" :class="{active : step == 4}"><div class="focus"><span>T</span></div></div>
			</div>
		</div>
		
		<div id="barcode" :class="{dark:darkbottom}">
			<v-svg clase="svg" choose="barcode"></v-svg>
		</div>
		
		<div id="scroll" :class="{off:!scroll}">
			<strong>The world is <b>Yours.</b></strong>
			<img src="/gestorproyectos/public/images/svgs/scroll.svg">
		</div>
		
		<div id="menu" :class="{dark:darkmenu}">
			<menu>
				<span :class="{active : step == 4}" @click="step = 4;change(4)">Contact</span>
				<span :class="{active : step == 3}" @click="step = 3;change(3)">Projects</span>
				<span :class="{active : step == 2}" @click="step = 2;change(2)">About</span>
				<span :class="{active : step == 1}" @click="step = 1;change(1)">Home</span>
				<span><a href="http://localhost:8080/gestorproyectos/public/login">Login </a></span>
			</menu>
		</div>
		
	</header>
</template>

<script>

	export default {
		props: ['path'],
		data() {
			return {
				menu: false,
				promo: false,
				scroll: true,
				scrolltop: 0,
				step: 1,
				steps: 4,
				time: 2000,
				moving: false,
				darktop:false,
				darkbottom:false,
				darkmenu:false,
				origen_height:0,
				about_height:0,
				workw_height:0,
				workb_height:0,
				talk_height:0,
			};
		},

		methods: {
			showMenu() {
				if (!this.menu) {
					this.menu = true;
				} else {
					this.menu = false;
				}
			},

			closeMenu() {
				this.menu = false;
			},
			
			
			next(){
				
				this.moving = true;
				
				if(this.step < this.steps){
					this.step += 1;
				}
				
				this.change(this.step);
					
			},
			
			prev(){
				
				this.moving = true;
				
				if(this.step > 1){
					this.step -= 1;
				}
				
				this.change(this.step);
				
			},
			
			
			change(step){
				
				console.log(step);
				
				document.getElementsByTagName('section')[step - 1].scrollIntoView({
			        behavior: 'smooth'
			    });
				
				
				setTimeout(()=>{
					
					this.moving = false;
					
				}, this.time);
				
			},
			
			
			handleMouseWheel(event) {
				
				if(!this.moving && event.deltaY < 10){

				    if (event.deltaY >= 0) {
	
				        //SCROLL DOWN
				        
				        this.next();
	
				    }else{
					    
					    //SCROLL UP
					    
					    this.prev();
					    
				    }
			    
			    }
			    
			    event.preventDefault();
			    return false;

			},
			
			
			setsizes(){
				
				this.origen_height = this.$root.$refs.origen.clientHeight;
				this.about_height = this.$root.$refs.about.clientHeight;
				this.workw_height = this.$root.$refs.workw.clientHeight;
				this.workb_height = this.$root.$refs.work.clientHeight - this.$root.$refs.workw.clientHeight;
				this.talk_height = this.$root.$refs.talk.clientHeight;
				
			},

		},
		mounted() {
			
			if(window.innerWidth > 800){
	
/*
				window.addEventListener('DOMMouseScroll', this.handleMouseWheelDOM);
				
				window.addEventListener('mousewheel', this.handleMouseWheel, {
				    passive: false
				});
				
				window.addEventListener('touchstart', this.touchStart, {
				    passive: false
				});
				
				window.addEventListener('touchmove', this.touchMove, {
				    passive: false
				});
*/
				
			}
			
			window.addEventListener('scroll', (e)=> {
			
				this.scrolltop = window.scrollY;
				
				if(this.scrolltop > 10){
					this.scroll = false;
				}else{
					this.scroll = true;
				}
				
				
				if(this.scrolltop > 10 && this.scrolltop < (this.about_height + this.workw_height + 10)){
					this.darkbottom = true;
				}else{
					this.darkbottom = false;
				}
				
				if(this.scrolltop > this.origen_height - 30 && this.scrolltop < (this.origen_height - 30 + this.about_height + this.workw_height)){
					this.darktop = true;
				}else{
					this.darktop = false;
				}
				
				
				if(this.scrolltop > (this.origen_height/2) && this.scrolltop < (this.origen_height + this.about_height + this.workw_height - (window.innerHeight / 2))){
					this.darkmenu = true;
				}else{
					this.darkmenu = false;
				}
				
				
			});
			
			window.addEventListener('resize', (e)=> {
			
				this.setsizes();
				
			});
			
			this.setsizes();
			
		},
		
		destroyed() {
			
			if(window.innerWidth > 800){
			
/*
			    window.removeEventListener('mousewheel', this.handleMouseWheel, {
			        passive: false
			    });
			    window.removeEventListener('DOMMouseScroll', this.handleMouseWheelDOM);
			    window.removeEventListener('touchstart', this.touchStart);
			    window.removeEventListener('touchmove', this.touchMove);
*/
			    
			}
		}
	};
</script>
