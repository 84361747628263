var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", [
    _c(
      "div",
      { class: { dark: _vm.darktop }, attrs: { id: "logo" } },
      [_c("v-svg", { attrs: { clase: "logo", choose: "logo" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { class: { dark: _vm.darktop }, attrs: { id: "from" } }, [
      _c("label", [_vm._v("México, MMXVII. ORIGEN ESTUDIO.")]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: { dark: _vm.darktop }, attrs: { id: "star" } },
      [_c("v-svg", { attrs: { clase: "svg", choose: "star" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: { dark: _vm.darkbottom }, attrs: { id: "target" } },
      [
        _c("v-svg", { attrs: { clase: "svg", choose: "target" } }),
        _vm._v(" "),
        _c("div", { attrs: { id: "steps" } }, [
          _c(
            "div",
            {
              staticClass: "step",
              class: { active: _vm.step == 1 },
              attrs: { id: "step1" },
            },
            [_c("div", { staticClass: "focus" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "step",
              class: { active: _vm.step == 2 },
              attrs: { id: "step2" },
            },
            [_c("div", { staticClass: "focus" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "step",
              class: { active: _vm.step == 3 },
              attrs: { id: "step3" },
            },
            [_c("div", { staticClass: "focus" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "step",
              class: { active: _vm.step == 4 },
              attrs: { id: "step4" },
            },
            [_vm._m(0)]
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: { dark: _vm.darkbottom }, attrs: { id: "barcode" } },
      [_c("v-svg", { attrs: { clase: "svg", choose: "barcode" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { class: { off: !_vm.scroll }, attrs: { id: "scroll" } }, [
      _vm._m(1),
      _vm._v(" "),
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/svgs/scroll.svg" },
      }),
    ]),
    _vm._v(" "),
    _c("div", { class: { dark: _vm.darkmenu }, attrs: { id: "menu" } }, [
      _c("menu", [
        _c(
          "span",
          {
            class: { active: _vm.step == 4 },
            on: {
              click: function ($event) {
                _vm.step = 4
                _vm.change(4)
              },
            },
          },
          [_vm._v("Contact")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            class: { active: _vm.step == 3 },
            on: {
              click: function ($event) {
                _vm.step = 3
                _vm.change(3)
              },
            },
          },
          [_vm._v("Projects")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            class: { active: _vm.step == 2 },
            on: {
              click: function ($event) {
                _vm.step = 2
                _vm.change(2)
              },
            },
          },
          [_vm._v("About")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            class: { active: _vm.step == 1 },
            on: {
              click: function ($event) {
                _vm.step = 1
                _vm.change(1)
              },
            },
          },
          [_vm._v("Home")]
        ),
        _vm._v(" "),
        _vm._m(2),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "focus" }, [_c("span", [_vm._v("T")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("strong", [_vm._v("The world is "), _c("b", [_vm._v("Yours.")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c(
        "a",
        {
          attrs: { href: "http://localhost:8080/gestorproyectos/public/login" },
        },
        [_vm._v("Login ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }