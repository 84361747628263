var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "cover" } }, [
    _c("div", { staticClass: "centro animation" }, [
      _c(
        "div",
        {
          class: [{ mid: _vm.mid }, { full: _vm.full }, { glow: _vm.glow }],
          attrs: { id: "box" },
        },
        [_vm._m(0), _vm._v(" "), _vm._m(1)]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "min",
            { mid: _vm.mid2 },
            { full: _vm.full2 },
            { glow: _vm.glow2 },
          ],
          attrs: { id: "box" },
        },
        [_vm._m(2), _vm._v(" "), _vm._m(3)]
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "svganimation" } }, [
        _c("div", { class: { glow: _vm.glow }, attrs: { id: "circle" } }),
        _vm._v(" "),
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "400.2099914550781",
              height: "400.1029968261719",
              viewBox: "0 0 400.21 400.103",
            },
          },
          [
            _c("path", {
              staticClass: "svg-elem-1",
              attrs: {
                d: "M-7424.715-3259.685c112.05,0,194.98,96.837,192.915,198.067-1.41,85.579-64.753,188.17-192.915,193.961-104.857.8-197-78.661-199.218-193.961s96.68-200.849,196.633-198.067",
                transform: "translate(7627.971 3263.753)",
                fill: "none",
                stroke: "#fff",
                "stroke-linecap": "round",
                "stroke-width": "6",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c("div", {
          class: [{ dot: _vm.dot }, { dotglow: _vm.dotglow }],
          attrs: { id: "dot" },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "half", attrs: { id: "right" } }, [
      _c("div", { staticClass: "semicircle" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "half", attrs: { id: "left" } }, [
      _c("div", { staticClass: "semicircle" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "half", attrs: { id: "right" } }, [
      _c("div", { staticClass: "semicircle" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "half", attrs: { id: "left" } }, [
      _c("div", { staticClass: "semicircle" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }