<template>


	<div id="contacto">
	

		<div id="formulario" :class="resultClass">
			
			<div class="side" id="left">
				<div class="input">
					<input type="text" placeholder="Name" v-model="name" />
				</div>
				
				<div class="input">
					<input type="text" placeholder="E-mail" v-model="email" />
				</div>
				
				<div class="input">
					<input type="text" placeholder="Phone" v-model="phone" />
				</div>
				
				<div class="input">
					<input type="text" placeholder="*Business" v-model="business" />
				</div>
			</div>
			
			<div class="side">
				
				<div class="input">
					<textarea name placeholder="Message" rows="3" v-model="message"></textarea>
				</div>
		
				<button class="boton" @click="send"><span>Send</span></button>
				
			</div>
			
				
	
			<div id="msg">{{ notification }}</div>
		</div>
	</div>

	
</template>

<script>
	
	import { required } from 'vuelidate/lib/validators';
	
	export default {
		data() {
			return {
				name: '',
				email: '',
				phone: '',
				business: '',
				message: '',
				notification: 'Send',
				alerts:[false,false,false,false],
				resultClass: '',
				errors:[],
				showErrors: false,
			};
		},
		validations: {
			name: {
				required,
			},
			email: {
				required,
			},
			phone: {
				required,
			},
			message: {
				required,
			},
		},
		methods: {
			
			
			validate() {
				this.errors = [];

				if (!this.$v.name.required) {
					this.errors.push('Type your name');
					this.alerts[0] = true;
				}

				if (!this.$v.email.required) {
					this.errors.push('Type your email');
					this.alerts[1] = true;
				}

				if (!this.$v.phone.required) {
					this.errors.push('Type your phone number');
					this.alerts[2] = true;
				}

				if (!this.$v.message.required) {
					this.errors.push('Type your message');
					this.alerts[3] = true;
				}

				if (_.isEmpty(this.errors)) {
					this.showErrors = false;
					
					return true;
					
				} else {
					this.showErrors = true;
					
					return false;
				}
			},

			initForm() {
				this.name =  '';
				this.email = '';
				this.phone = '';
				this.business = '';
				this.message = '';
			},
			
			removeAlerts(){
				this.errors = [];
				this.showErrors = false;
				this.alerts = [false,false,false,false];
			},
			
			async send() {
				if (!this.validate()) {
					this.bad('error');
					return false;
				}

				this.loading('enviando...');

				let contacto = {
					site_id: '31',
					token: 'U3qb5R5RraSe6tLmIoRZZZnzTFzwgwmQA5EU4ZWl',
					data: {
						Name: this.name,
						Email: this.email,
						Phone: this.phone,
						Business: this.business,
						Message: this.message,
					},
				};

				let response = await window.axios.post('https://mail.planetoi.de/api/send', contacto);

				if (response.status) {
					this.removeAlerts();
					this.initForm();
					this.good('Sent');
				} else {
					this.bad('Error');
				}
			},

			good(msg) {
				this.notification = msg;
				this.resultClass = 'ok';
				alert('Message sent.')
			},

			bad(msg) {
				alert(this.errors);
				this.notification = msg;
				this.resultClass = 'error';

				setTimeout(() => {
					this.resultClass = '';
				}, 4000);
			},

			loading(msg) {
				this.notification = msg;
				this.resultClass = 'waiting';
			},
		},
	};
</script>


