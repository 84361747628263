/**
 * Basics
 */
import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');

window.Vue = Vue;

/**
 * Autoregitro de componentes site
 *
 * Eg. ./components/site/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./components/site', true, /\.vue$/i);

files.keys().map(key =>
	Vue.component(
		key
			.split('/')
			.pop()
			.split('.')[0],
		files(key).default,
	),
);

/**
 * Instancia de Vue
 */

import eShop from '@/plugins/eshop';
import Vuelidate from 'vuelidate';
import Site from '@/plugins/site';
import router from '@/router';
import store from '@/store';
import VueDefaults from '@/plugins/instance';


Vue.use(VueDefaults);
Vue.use(Site);
Vue.use(eShop);
Vue.use(Vuelidate);
Vue.use(VueScrollTo);

window.Events = new Vue();

window.App = new Vue({
	el: '#app',
	components: {
		//'example-component': () => import('@/lazycomponents/ExampleComponent'),
		App: () => import('@/lazycomponents/App'),
		planetoide: () => import('@/lazycomponents/Planetoide'),
	},
	store,
	router,
	// i18n,
	// template: '<App/>',
	data: {
		locale: null,
		fps:null,
		fpoptions: {
	        licenseKey: false,
	        menu: '#menu',
	        anchors: ['page1', 'page2', 'page3'],
	        sectionsColor: ['#41b883', '#ff5f45', '#0798ec']
	    },
	},
	methods: {
		setLocale(locale) {
			this.locale = locale;
		},
	},
	mounted(){
		
	
		
	}
});
