var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "portfolio" } }, [
    _c("div", { ref: "works", attrs: { id: "works" } }, [
      _c("div", { ref: "projects", attrs: { id: "projects" } }, [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _vm._m(5),
        _vm._v(" "),
        _vm._m(6),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _vm._m(8),
        _vm._v(" "),
        _vm._m(9),
        _vm._v(" "),
        _vm._m(10),
        _vm._v(" "),
        _vm._m(11),
        _vm._v(" "),
        _vm._m(12),
        _vm._v(" "),
        _vm._m(13),
        _vm._v(" "),
        _vm._m(14),
        _vm._v(" "),
        _vm._m(15),
        _vm._v(" "),
        _vm._m(16),
        _vm._v(" "),
        _vm._m(17),
        _vm._v(" "),
        _vm._m(18),
        _vm._v(" "),
        _vm._m(19),
        _vm._v(" "),
        _vm._m(20),
        _vm._v(" "),
        _vm._m(21),
        _vm._v(" "),
        _vm._m(22),
        _vm._v(" "),
        _vm._m(23),
        _vm._v(" "),
        _vm._m(24),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "nav" } }, [
        _c("div", {
          staticClass: "side",
          attrs: { id: "prev" },
          on: {
            mousedown: _vm.prev,
            mouseup: _vm.stop,
            touchstart: _vm.prev,
            touchend: _vm.stop,
          },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "side",
          attrs: { id: "next" },
          on: {
            mousedown: _vm.next,
            mouseup: _vm.stop,
            touchstart: _vm.next,
            touchend: _vm.stop,
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { ref: "scroller", attrs: { id: "scroller" } }, [
      _c("div", { ref: "line", staticClass: "line" }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/01.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/02.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/03.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/04.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/05.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/06.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/07.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/08.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/09.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/10.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/11.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/12.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/13.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/14.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/15.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/16.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/17.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/18.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/19.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/20.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/21.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/22.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/23.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/24.jpg" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "project" }, [
      _c("img", {
        attrs: { src: "/gestorproyectos/public/images/portfolio/25.jpg" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }